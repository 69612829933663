body {
  font-family: "CoFo Sans";
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.items-wrap {
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;

}
.items-wrap:before,
.items-wrap:after {
  content: "";
  height: 100%;
  top: 0;
  width: 10%;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
.items-wrap:before {
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(241, 245, 249, 1) 0%,
    rgba(241, 245, 249, 0) 100%
  );
}
.items-wrap:after {
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(241, 245, 249, 0) 0%,
    rgba(241, 245, 249, 1) 100%
  );
}
.items {
  flex-shrink: 0;
  display: flex;
  counter-reset: item;
  justify-content: space-around;
  min-width: 100%;
}

.item:hover {
  transform: scale(1.05);
}

.item:before {
  content: counter(item);
}

.marquee {
  animation: scroll 20s linear infinite;
}
.reverce {
  animation-direction: reverse;
}
.items-wrap:hover .marquee {
  animation-play-state: paused;
}

.perfscan {
  margin: 20px 0;
  text-align: center;
  bottom: 0;
  background: #fff;
  padding: 5px;
}
.perfscan hr {
  border: solid #999;
  border-width: 1px 0 0 0;
  max-width: 50%;
  margin: 0 auto 20px;
}
.perfscan a {
  color: #000;
  font-weight: bold;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 20px));
  }
}